import React from "react";
import Posts from "./Posts";
import ToolButton from "../components/ToolButton";
import VideoSection from "../components/VideoSection";

export const latestVideoURL = "https://www.youtube.com/embed/IOB0j4BhUxM";

const Home = () => {
	return (
		<div className="max-w-5xl mx-auto px-4 py-8">
			<section className="mb-12">
				<h2 className="text-2xl font-bold mb-4">Tools</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
					<ToolButton
						title="Qantas Flight Finder"
						description="Find Rare Qantas Reward Flights"
						href="/tools/qfflightfinder"
						emoji="🛩️"
					/>
					<ToolButton
						title="Qantas Wine Tracker"
						description="Buy wine purely for the points?!"
						href="/tools/qfwine"
						emoji="🍷"
					/>
				</div>
			</section>

			<VideoSection videoUrl={latestVideoURL} />

			<section className="mt-12">
				<h2 className="text-2xl font-bold mb-4">Latest Posts</h2>
				<div className="space-y-6">
					<Posts />
				</div>
			</section>
		</div>
	);
};

export default Home;